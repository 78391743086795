<template>
  <div class="row tour-settings" v-if="!loading">
    <div class="section">
      <h5>Divisions</h5>
      <div class="left"></div>
      <div class="right"></div>
      <TheTjingDivisionEditor
        class="mb-3"
        v-for="division in tourData.divisions"
        :key="division.type"
        :tourId="$route.params.tourId"
        :division="division"
        :tourPassAllowed="stripeTour"
        :usedDivisions="usedDivisions"
        :availableDivisions="divisions"
        :currencies="currencies"
      />
      <TheTjingDivisionEditor
        class="mb-3"
        :tourPassAllowed="stripeTour"
        :usedDivisions="usedDivisions"
        :availableDivisions="divisions"
        :currencies="currencies"
        :tourId="$route.params.tourId"
        :addMode="true"
        @click="addDivisionMode = true"
      />
    </div>
  </div>
</template>

<script>
import TheTjingDivisionEditor from "@/components/TheTjingDivisionEditor";

export default {
  name: "TourDivisions",
  components: {
    TheTjingDivisionEditor,
  },
  data() {
    return {
      tourData: {},
      me: {},
      loading: true,
      editDivision: "",
      addDivisionMode: false,
      enableTotalRounds: false,
      currencies: [],
      divisions: [],
      tourTypes: [],
      globalSettings: {
        showAddDivision: false,
      },
    };
  },
  watch: {
    $route: "loadData",
  },
  computed: {
    stripeTour() {
      if (this.tourData.connectedAccount == null) return false;
      return true;
    },
    usedDivisions() {
      let divisions = [];

      for (var t = 0; t < this.tourData.divisions.length; t++) {
        divisions.push(this.tourData.divisions[t].type);
      }

      return divisions;
    },
  },
  methods: {
    saveEditedDivision(division) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateDivision($UpdateDivision: UpdateDivisionInput!){
            UpdateDivision(divisionId:"${division.id}",input:$UpdateDivision, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            UpdateDivision: {
              reservedSpots: division.reservedSpots,
              registrationFee: division.registrationFee,
              tourPassFee: division.tourPassFee,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Updated",
            type: "success",
          });
          this.loadData();
          this.editDivision = "";
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });
    },
    deleteDivision(divisionId) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            DeleteDivision(divisionId:"${divisionId}")
          }
          `,
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.loadData();
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });
    },
    createDivision(division) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation CreateDivision($CreateDivision: DivisionInput!){
            CreateDivision(tourId:"${this.$router.currentRoute.params.tourId}",input:$CreateDivision){
              id
            }
          }
          `,
          variables: {
            CreateDivision: {
              type: division.type,
              maxSpots: division.maxSpots,
              reservedSpots: division.reservedSpots,
              registrationFee: division.registrationFee,
            },
          },
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch(() => {});
    },
    addDivision(division) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation CreateDivision($CreateDivision: DivisionInput!){
            CreateDivision(tourId:"${this.$router.currentRoute.params.tourId}",input:$CreateDivision, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            CreateDivision: {
              type: division.type,
              maxSpots: division.maxSpots,
              reservedSpots: division.reservedSpots,
              registrationFee: division.registrationFee,
              tourPassFee: division.tourPassFee,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.globalSettings.showAddDivision = false;
          this.loadData();
        })
        .catch(() => {});
    },
    loadData() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            divisionTypes{
              position
              name
              type
            }
            currencies{
              name
              code
              digits
              isEnabledForPayments
            }
            tour(tourId:"${this.$router.currentRoute.params.tourId}"){
              id
              connectedAccount{
                id
                name
              }
              divisions{
                id
                type
                name
                isDeletable
                maxSpots
                reservedSpots
                tourPassFee{
                  value
                }
                registrationFee{
                  value
                  currency
                  formatted
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.currencies = result.data.data.currencies;
          this.divisions = result.data.data.divisionTypes;
          this.tourData = result.data.data.tour;
          this.me = result.data.data.me;
          this.tourTypes = result.data.data.tourTypes;

          if (result.data.data.tour.scoreBasedOnNrOfEvents != null) {
            this.enableTotalRounds = true;
          }

          this.loading = false;
          this.$store.dispatch("loadManagedEvents");
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
h5 {
  font-size: 16px;
  margin-bottom: 24px;
  @include Gilroy-Bold;
}

a {
  color: $teal;

  transition: 0.3s;

  &:hover {
    color: $teal;
    text-decoration: underline;
  }
}

.tour-settings {
  width: 100%;
  padding: 0 12px;
  .section {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 3px $sleet;
    margin-bottom: 24px;

    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .tour-settings {
    .section {
      padding: 30px 30px 40px 30px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 3px $sleet;
      margin-bottom: 24px;
    }
  }
  h5 {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
</style>
